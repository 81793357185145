export const client = {
  namespaced: true,
  state: {
    clients: []
  },
  mutations: {
    setClient (state, client) {
      state.clients.unshift(client)
    },
    setClients (state, clients) {
      state.clients = clients
    },
    removeClient (state, id) {
      const index = state.clients.findIndex(r => r.id == id)
      state.clients.splice(index, 1)
    },
    updateclient (state, client) {
      const index = state.clients.findIndex(r => r.id == client.id)
      state.clients.splice(index, 1, client)
    }
  },
  actions: {
    setClients ({ commit }, clients) {
      commit('setClients', clients)
    },
    setClient ({ commit }, client) {
      commit('setClient', client)
    },
    removeClient ({ commit }, id) {
      commit('removeClient', id)
    },
    updateClient ({ commit }, client) {
      commit('updateclient', client)
    }
  },
  getters: {
    getClients: (state) => {
      return state.clients
    }
  },
}