import Vue from "vue";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, split } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import VueApollo from "vue-apollo";
import dotenv from "dotenv";
import { GC_AUTH_TOKEN } from "./../constants/settings";

dotenv.config();

const apiUrl = process.env.VUE_APP_API_URL;
const appUrl = process.env.VUE_APP_URL;

export const API_URL = apiUrl;

export const API_URL_WHIRELAB = "https://api.whirelab.com";
// export const API_URL_WHIRELAB = "https://recrutment-api-demo.whirelab.com";
// export const API_URL_WHIRELAB = 'http://localhost:3000'

export const API_URL_2 = "wss://kenmare-api.whirelab.com";
// export const API_URL_2 = "wss://kenmare-api-demo.whirelab.com";
// export const API_URL_2 = 'ws://localhost:3004'

export const API_URL_WHIRELAB_2 = "wss://api.whirelab.com";
// export const API_URL_WHIRELAB_2 = "wss://recrutment-api-demo.whirelab.com";
// export const API_URL_WHIRELAB_2 = 'ws://localhost:3000'

export const APP_URL = appUrl;

const httpLink = createUploadLink({
  uri: API_URL,
});

const wsLink = new WebSocketLink({
  uri: API_URL_2,
  options: {
    reconnect: true,
  },
});

const httpLinkWhirelab = createUploadLink({
  uri: API_URL_WHIRELAB,
});

const wsLinkWhirelab = new WebSocketLink({
  uri: API_URL_WHIRELAB_2,
  options: {
    reconnect: true,
  },
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

const linkWhirelab = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLinkWhirelab,
  httpLinkWhirelab
);

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(GC_AUTH_TOKEN);
  const locale = localStorage.getItem("lang");
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
      locale: `${locale}`,
    },
  });

  return forward(operation);
});

const apolloClient = new ApolloClient({
  link: authMiddleware.concat(link),
  cache: new InMemoryCache({ addTypename: false }),
  connectToDevTools: true,
});

const apolloClientWhirelab = new ApolloClient({
  link: authMiddleware.concat(linkWhirelab),
  cache: new InMemoryCache({ addTypename: false }),
  connectToDevTools: true,
});

const apolloProvider = new VueApollo({
  clients: {
    apolloClient,
    apolloClientWhirelab,
  },
  defaultClient: apolloClient,
});

Vue.use(VueApollo);

export default apolloProvider;

export { apolloClient };
