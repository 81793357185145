const Profile = () => import("../views/Profile.vue");
const Role = () => import("../views/Role.vue");
const User = () => import("../views/User.vue");
const TrainingArea = () => import("../views/TrainingArea.vue");

export default [
  {
    path: "entity/profile",
    component: Profile,
    meta: { requiresAuth: true },
    name: "profile",
  },
  {
    path: "entity/roles",
    component: Role,
    meta: { requiresAuth: true },
    name: "roles",
  },
  {
    path: "entity/training-area",
    component: TrainingArea,
    meta: { requiresAuth: true },
    name: "training-area",
  },
  {
    path: "entity/users",
    component: User,
    meta: { requiresAuth: true },
    name: "users",
  },
];
