export const role = {
  namespaced: true,
  state: {
    roles: []
  },
  mutations: {
    setRole (state, role) {
      state.roles.unshift(role)
    },
    setRoles (state, roles) {
      state.roles = roles
    },
    removeRole (state, id) {
      const index = state.roles.findIndex(r => r.id == id)
      state.roles.splice(index, 1)
    },
    updateRole (state, role) {
      const index = state.roles.findIndex(r => r.id == role.id)
      state.roles.splice(index, 1, role)
    }
  },
  actions: {
    setRoles ({ commit }, roles) {
      commit('setRoles', roles)
    },
    setRole ({ commit }, role) {
      commit('setRole', role)
    },
    removeRole ({ commit }, id) {
      commit('removeRole', id)
    },
    updateRole ({ commit }, role) {
      commit('updateRole', role)
    }
  },
  getters: {
    getRoles: (state) => {
      return state.roles
    }
  },
}