export const user = {
  namespaced: true,
  state: {
    users: []
  },
  mutations: {
    setUser (state, user) {
      state.users.unshift(user)
    },
    setUsers (state, users) {
      state.users = users
    },
    removeUser (state, id) {
      const index = state.users.findIndex(u => u.id == id)
      state.users.splice(index, 1)
    },
    updateUser (state, user) {
      const index = state.users.findIndex(u => u.id == user.id)
      state.users.splice(index, 1, user)
    }
  },
  actions: {
    setUser ({ commit }, user) {
      commit('setUser', user)
    },
    setUsers ({ commit }, users) {
      commit('setUsers', users)
    },
    removeUser({ commit }, id) {
      commit('removeUser', id)
    },
    updateUser({ commit }, user) {
      commit('updateUser', user)
    }
  },
  getters: {
    getUsers: (state) => {
      return state.users
    }
  },
}