import testRoute from './../modules/test/router'
import profileRoute from './../modules/profile/router'
const Dashboard = () => import('../views/Dashboard.vue')


export default [
  {
    path: '/partner',
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      ...profileRoute,
      ...testRoute,
    ]
  },
 
]