import gql from "graphql-tag";

export default gql`
  {
    id
    email
    entity {
      id
      name
    }
    name
    office
    photo
    type
    telephone
    role {
      id
      name
      permissions {
        id
        name
      }
    }
    passwordReset
  }
`;
