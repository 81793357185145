module.exports = [
  { text: "Engenheiro", key: "engineer" },
  { text: "Analista", key: "analyst" },
  { text: "Advogado", key: "lawyer" },
  { text: "Procurador", key: "attorney" },
  { text: "Promotor de Justiça", key: "justice_promoter" },
  { text: "Juiz", key: "judge" },
  { text: "Supervisor", key: "supervisor" },
  { text: "Técnico", key: "technician" },
  { text: "Técnico de Biologia", key: "biology_technician" },
  { text: "Contabilista", key: "accountant" },
  { text: "Consultor", key: "consultant" },
  { text: "Auditor", key: "controller" },
  { text: "Analista financeiro", key: "financial_analyst" },
  { text: "Gestor", key: "manager" },
  { text: "Representante de Vendas", key: "sales_representative" },
  { text: "Técnico de Informática", key: "computer_technician" },
  { text: "Técnicos de Farmácia", key: "pharmacy_technicians" },
  { text: "Inspector", key: "Inspector" },
  { text: "Técnico Comercial ", key: "commercial_technician" },
  { text: "Médico", key: "doctor" },
  { text: "Director", key: "director" },
  { text: "Chefe do Projecto ", key: "project_head" },
  { text: "Coordenador de Segurança", key: "security_coordinator" },
  { text: "Motoristas de Ambulâncias", key: "ambulance_drivers" },
  { text: "Técnico de Medicina", key: "medical_technician" },
  { text: "Motorista", key: "driver" },
  { text: "Operador de Grua", key: "crane_operator" },
  { text: "Administração e Secretariado", key: "administration_and_secretariat" },
  { text: "Aquisições e Procurement", key: "procurement_and_procurement" },
  { text: "Assistente", key: "assistant" },
  { text: "Auditoria e Consultoria", key: "audit_and_consulting" },
  { text: "Comercial e Vendas", key: "commercial_and_sales" },
  { text: "Desenvolvimento Empresarial", key: "business_development" },
  { text: "Direito e Justiça", key: "law_and_justice" },
  { text: "Engenharia", key: "engineering" },
  { text: "Engenheiro Electrotécnico", key: "electrical_engineer" },
  { text: "Engenheiro Mecânico", key: "mechanical_engineer" },
  { text: "Finanças e Contabilidade", key: "finance_and_accounting" },
  { text: "Formação e Ensino", key: "training_and_education" },
  { text: "Geociências", key: "Geosciences" },
  { text: "Gestão de Projectos", key: "project_management" },
  { text: "Gestão de Qualidade", key: "quality_management" },
  { text: "Gestão e Assessoria", key: "management_and_advisory" },
  { text: "Gestão e Programação", key: "management_and_programming" },
  { text: "Gestão Financeira", key: "financial_management" },
  { text: "Hotelaria e Turismo", key: "hotel_and_tourism" },
  { text: "Informática e Programação", key: "computing_and_programming" },
  { text: "Manutenção e Técnica", key: "maintenance_and_technique" },
  { text: "Marketing e Publicidade", key: "marketing_and_advertising" },
  { text: "Meio Ambiente", key: "environment" },
  { text: "Monitoria e Avaliação", key: "monitoring_and_evaluation" },
  { text: "Recursos Humanos", key: "human_resources" },
  { text: "Saúde", key: "cheers" },
  { text: "Saúde e Farmacêutica", key: "health_and_pharmaceuticals" },
  { text: "Supervisão e Coordenação", key: "supervision_and_coordination" },
  { text: "Transportes e Logística", key: "Transport and Logistics" },
  { text: "Vendas", key: "sales" },
  { text: "Todos", key: "all" },
]