import candidateRoute from './../modules/candidate/router'
import entityRoute from './../modules/entity/router'
import partnerRoute from './../modules/partner/router'
import settingRoute from './../modules/setting/router'
import supportRoute from './../modules/support/router'
import testRoute from './../modules/test/router'
import webHomeRoute from './../modules/page/home/router'
import webRecruterRoute from './../modules/page/recruter/router'
import webCandidateRoute from './../modules/page/candidate/router'

const Dashboard = () => import('./../views/Dashboard.vue')

export default [{
  path: '/root',
  component: Dashboard,
  meta: {
    requiresAuth: true
  },
  children: [
    ...candidateRoute,
    ...entityRoute,
    ...partnerRoute,
    ...settingRoute,
    ...supportRoute,
    ...testRoute,
    ...webHomeRoute,
    ...webRecruterRoute,
    ...webCandidateRoute
  ]
}]