import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from "./auth.module";
import { candidate } from "./candidate.module";
import { client } from "./client.module";
import { entity } from "./entity.module";
import { interview } from "./interview.module";
import { library } from './library.module';
import { moduleLocal } from './moduleLocal.module';
import { partner } from './partner.module';
import { plan } from './plan.module';
import { role } from './role.module';
import { test } from './test.module';
import { user } from './user.module';
import { vacancy } from './vacancy.module';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    candidate,
    client,
    entity,
    interview,
    library,
    moduleLocal,
    partner,
    plan,
    role,
    test,
    user,
    vacancy
  }
})
