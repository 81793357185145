// const Activity = () => import('../views/Activity.vue')
const Test = () => import('../views/Test.vue')

export default [
  // {
  //   path: 'entity/test/activities',
  //   component: Activity,
  //   meta: { requiresAuth: true },
  //   name: 'tests.activities'
  // },
  {
    path: 'tests',
    component: Test,
    meta: { requiresAuth: true },
    name: 'root.tests'
  },
]