const CandidateVacancy = () => import('../views/CandidateVacancy.vue')
const Home = () => import('../views/Home.vue')
const PrivateVacancy = () => import('../views/PrivateVacancy.vue')
const VacancyDetails = () => import('../views/VacancyDetails.vue')

export default [
  {
    path: 'candidate',
    component: Home,
    meta: { requiresAuth: true },
    alias: ['home', '']
  },
  {
    path: 'candidate/vacancies',
    component: CandidateVacancy,
    meta: { requiresAuth: true },
    name: 'candidate.vacancies'
  },
  {
    path: 'candidate/vacancies/private',
    component: PrivateVacancy,
    meta: { requiresAuth: true },
    name: 'vacancies.private'
  },
  {
    path: 'candidate/vacancy/:id',
    component: VacancyDetails,
    meta: { requiresAuth: true },
    name: 'vacancy.details',
    props: true
  }
]