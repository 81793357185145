module.exports = [
  { text: "Moçambicano", key: "Mozambican" },
  { text: "Português", key: "Portuguese" },
  { text: "África do Sul", key: "South_African" },
  { text: "Zimbabuense", key: "Zimbabwean" },
  { text: "Da Namíbia", key: "Namibian" },
  { text: "Da Namíbia", key: "Namibian" },

  { text:'boliviano',key: "Bolivian" },
  { text:'brasileiro',key: "Brazilian" },
  { text:'chileno',key: "Chilean" },
  { text:'colombiano',key: "Colombian" },
  { text:'Costa riquenho',key: "Costa_Rican" },
  { text:'cubano',key: "Cuban" },
  { text:'Dominicano',key: "Dominican" },
  { text:'Equatoriana',key: "Ecuadorian" },
  { text:'guatemalteco',key: "Guatemalan" },
  { text:'Guianense',key: "Guyanese" },

  { text:'Da Guiana',key: "Guyanian" },
  { text:'haitiano',key: "Haitian" },
  { text:'Hondurenho',key: "Honduran" },
  { text:'jamaicano',key: "Jamaican" },
  { text:'mexicano',key: "Mexican" },
  { text:'Nicaraguense',key: "Nicaraguan" },
  { text:'Panamenho',key: "Panamanian" },
  { text:'paraguaio',key: "Paraguayan" },
  { text:'peruano',key: "Peruvian" },
  { text:'Puertoriano',key: "Puertorian" },
  { text:'Dominicano',key: "Dominican" },
  { text:'Saint-Christian',key: "Saint_Christian" },
  { text:'São vicentino',key: "Saint_Vincentian" },
  { text:'Santa Lucense',key: "Santa_Lucense" },
  { text:'Surinamesa',key: "Surinamese" },
  { text:'Trindaniano',key: "Trindanian" },
  { text:'venezuelano',key: "Venezuelan" },
  { text:'alemão',key: "German" },
  { text:'austríaco',key: "Austrian" },
  { text:'Belga',key: "Belgian" },
  { text:'croata',key: "Croatian" },
  { text:'dinamarquês',key: "Danish" },
  { text:'De Antigua',key: "Antiguan" },
  { text:'Argentino',key: "Argentine" },
  { text:'Das Bahamas',key: "Bahamian" },
  { text:'Barbadense',key: "barbadian" },
  { text:'De Belize',key: "Belizean" },
  { text:'Eslovaco',key: "Slovak" },
  { text:'esloveno',key: "Slovenian" },
  { text:'espanhol',key: "Spanish" },
  { text:'francês',key: "French" },
  { text:'grego',key: "Greek" },
  { text:'húngaro',key: "Hungarian" },
  { text:'irlandês',key: "Irish" },
  { text:'italiano',key: "Italian" },
  { text:'Noruega',key: "Norway" },
  { text:'holandês',key: "Dutch" },
  { text:'polonês',key: "Polish" },
  { text:'inglês',key: "English" },
  { text:'galês',key: "Welsh" },
  'Escocês',
  'Romeno',
  'Russo',
  'Sérvio',
  'Sueco',
  'Suíço',
  'Turco',
  'Ucraniano',
  'Americano',
  'Canadense',
  'Angolano',
  'Sul-africano',
  'Zimbabuense',
  'Argélia',
  'Comorense',
  'Egípcio',
  'Líbio',
  'Marroquino',
  'Ganés',
  'Queniano',
  'Ruandês',
  'Ugandense',
  'Bechuano',
  'Marfinense',
  'Camaronense',
  'Nigeriano',
  'Somali',
  'Australiano',
  'Neozelandês',
  'Afegão',
  'Saudita',
  'Armeno',
  'Bangladesh',
  'Chinês',
  'Norte-coreano',
  'Sul-coreano',
  'Indiano',
  'Indonésio',
  'Iraquiano',
  'Iraniano',
  'Israelita',
  'Japonês',
  'Malaio',
  'Nepalês',
  'Omanense',
  'Paquistanês',
  'Palestino',
  'Qatarense',
  'Sírio',
  'Cingalês',
  'Tailandês',
  'Timorense',
  'Árabe',
  'Vietnamita',
  'Iemenita'
]