module.exports = [
  { text: "Mozambican", key: "Mozambican" },
  { text: "Portuguese", key: "Portuguese" },
  { text: "South African", key: "South_African" },
  { text: "Zimbabwean", key: "Zimbabwean" },
  { text: "Namibian", key: "Namibian" },
  { text: "Namibian", key: "Namibian" },

  { text:'Bolivian',key: "Bolivian" },
  { text:'Brazilian',key: "Brazilian" },
  { text:'Chilean',key: "Chilean" },
  { text:'Colombian',key: "Colombian" },
  { text:'Costa Rican',key: "Costa_Rican" },
  { text:'Cuban',key: "Cuban" },
  { text:'Dominican',key: "Dominican" },
  { text:'Ecuadorian',key: "Ecuadorian" },
  { text:'Guatemalan',key: "Guatemalan" },
  { text:'Guyanese',key: "Guyanese" },

  { text:'Guyanian',key: "Guyanian" },
  { text:'Haitian',key: "Haitian" },
  { text:'Honduran',key: "Honduran" },
  { text:'Jamaican',key: "Jamaican" },
  { text:'Mexican',key: "Mexican" },
  { text:'Nicaraguan',key: "Nicaraguan" },
  { text:'Panamanian',key: "Panamanian" },
  { text:'Paraguayan',key: "Paraguayan" },
  { text:'Peruvian',key: "Peruvian" },
  { text:'Puertorian',key: "Puertorian" },
  { text:'Dominican',key: "Dominican" },
  { text:'Saint-Christian',key: "Saint_Christian" },
  { text:'Saint Vincentian',key: "Saint_Vincentian" },
  { text:'Santa Lucense',key: "Santa_Lucense" },
  { text:'Surinamese',key: "Surinamese" },
  { text:'Trindanian',key: "Trindanian" },
  { text:'Venezuelan',key: "Venezuelan" },
  { text:'German',key: "German" },
  { text:'Austrian',key: "Austrian" },
  { text:'Belgian',key: "Belgian" },
  { text:'Croatian',key: "Croatian" },
  { text:'Danish',key: "Danish" },
  { text:'Antiguan',key: "Antiguan" },
  { text:'Argentine',key: "Argentine" },
  { text:'Bahamian',key: "Bahamian" },
  { text:'Barbadian',key: "barbadian" },
  { text:'Belizean',key: "Belizean" },
  { text:'Slovak',key: "Slovak" },
  { text:'Slovenian',key: "Slovenian" },
  { text:'Spanish',key: "Spanish" },
  { text:'French',key: "French" },
  { text:'Greek',key: "Greek" },
  { text:'Hungarian',key: "Hungarian" },
  { text:'Irish',key: "Irish" },
  { text:'Italian',key: "Italian" },
  { text:'Norway',key: "Norway" },
  { text:'Dutch',key: "Dutch" },
  { text:'Polish',key: "Polish" },
  { text:'English',key: "English" },
  { text:'Welsh',key: "Welsh" },
  'Scottish',
  'Romanian',
  'Russian',
  'Serbian',
  'Swedish',
  'Swiss',
  'Turkish',
  'Ukrainian',
  'American',
  'Canadian',
  'Angolan',
  'South African',
  'Zimbabwean',
  'Algeria',
  'Comoros',
  'Egyptian',
  'Libyan',
  'Moroccan',
  'Ganes',
  'Kenyan',
  'Rwandan',
  'Ugandanian',
  'Bechuan',
  'Ivorian',
  'Cameroonian',
  'Nigerian',
  'Somali',
  'Australian',
  'New Zealander',
  'Afghan',
  'Saudi',
  'Armeno',
  'Bangladesh',
  'Chinese',
  'North Korean',
  'South Korean',
  'Indian',
  'Indonesian',
  'Iraqi',
  'Iranian',
  'Israelite',
  'Japanese',
  'Malay',
  'Nepalese',
  'Omani',
  'Pakistani',
  'Palestinian',
  'Qataris',
  'Syrian',
  'Singhalese',
  'Thai',
  'Timorese',
  'Arabic',
  'Vietnamese',
  'Yemenite'
]