const Login = () => import("./../views/Login.vue");
const Recover = () => import("./../views/Recover.vue");
const RecoverPassword = () => import("./../views/RecoverPassword.vue");
const ResetPassword = () => import("./../views/ResetPassword.vue");
const VerifyTwoFactorCode = () => import("./../views/VerifyTwoFactorCode.vue");

export default [
  { path: "/login", component: Login },
  {
    path: "/recover/",
    component: Recover,
  },
  {
    path: "/recover_password/",
    component: RecoverPassword,
  },
  {
    path: "/reset-password/",
    component: ResetPassword,
  },
  {
    path: "/verify-two-factory-code/",
    component: VerifyTwoFactorCode,
  },
];
