export const auth = {
  namespaced: true,
  state: {
    user: null
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    updateCandidate (state, candidate) {
      state.user.candidate = candidate
    },
    updateEntity (state, entity) {
      state.user.entity = entity
    }
  },
  actions: {
    setUser ({ commit }, user) {
      commit('setUser', user)
    },
    updateCandidate ({ commit }, candidate) {
      commit('updateCandidate', candidate)
    },
    updateEntity ({ commit }, entity) {
      commit('updateEntity', entity)
    }
  },
  getters: {
    getCurrentUser: (state) => {
      return state.user
    },
    getCandidate: (state) => {
      return state.candidate
    }
  },
}