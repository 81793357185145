const Role = () => import('../views/Role.vue')
const User = () => import('../views/User.vue')
export default [
  {
    path: 'root/roles',
    component: Role,
    meta: { requiresAuth: true },
    name: 'root.roles'
  },
  {
    path: 'root/users',
    component: User,
    meta: { requiresAuth: true },
    name: 'root.users'
  },
]