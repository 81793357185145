module.exports = [
  {
    text: "Business Administration",
    key: "area_1",
  },
  {
    text: "Network and Systems Administration",
    key: "area_2",
  },
  {
    text: "Administration and Management of Computer/Information Technology",
    key: "area_3",
  },
  {
    text: "Business Administration and Management",
    key: "area_4",
  },
  {
    text: "Administration and Business",
    key: "area_5",
  },
  {
    text: "Public Administration",
    key: "area_6",
  },
  {
    text: "Administration, Business and Marketing",
    key: "area_7",
  },
  {
    text: "Administration/Sports Management",
    key: "area_8",
  },
  {
    text: "Agroeconomics and Agricultural Extension",
    key: "area_9",
  },
  {
    text: "Agronomy - Soils and Plant Nutrition",
    key: "area_10",
  },
  {
    text: "Food, Nutrition and Health",
    key: "area_11",
  },
  {
    text: "Animation, Interactive Technology, Video Design and Special Effects",
    key: "area_12",
  },
  { text: "Anthropology", key: "area_13" },
  { text: "Archaeology and Heritage Management", key: "area_14" },
  { text: "Architecture and Physical Planning", key: "area_15" },
  { text: "Landscape Architecture", key: "area_16" },
  { text: "Naval Architecture and Engineering", key: "area_17" },
  { text: "Architecture and Urbanism", key: "area_18" },
  { text: "Art and Art History", key: "area_19" },
  { text: "Metal Art and Jewelry", key: "area_20" },
  { text: "Public Arts", key: "area_21" },
  { text: "Visual Arts, Dance and Theatre", key: "area_22" },
  { text: "Audiology and Speech/Language Pathology", key: "area_23" },
  { text: "Veterinary Medicine Assistant", key: "area_24" },
  { text: "Legal Assistant", key: "area_25" },
  { text: "Library science", key: "area_26" },
  { text: "Library and Information Science", key: "area_27" },
  { text: "Bioengineering and Biomedical Engineering", key: "area_28" },
  { text: "Bioinformatics", key: "area_29" },
  { text: "Biology - General", key: "area_30" },
  { text: "Applied Biology", key: "area_31" },
  { text: "Biology and Health", key: "area_32" },
  { text: "Human Biology", key: "area_33" },
  { text: "Aquatic and Coastal Marine Biology", key: "area_34" },
  { text: "Marine Biology and Biological Oceanography", key: "area_35" },
  { text: "Molecular Biology", key: "area_36" },
  { text: "Biology/Biological Sciences - General", key: "area_37" },
  { text: "Biochemistry", key: "area_38" },
  { text: "Biochemistry and Molecular Biology", key: "area_39" },
  { text: "Biotechnology", key: "area_40" },
  { text: "Botany/Plant Biology", key: "area_41" },
  { text: "Cartography and Geological Survey", key: "area_42" },
  {
    text: "Retail Management",
    key: "area_172"
  },
  {
    text: "Higher Education Management",
    key: "area_173"
  },
  {
    text: "Operational Management and Supervision",
    key: "area_174"
  },
  {
    text: "Financial Management",
    key: "area_175"
  },
  {
    text: "Hospital Management",
    key: "area_176"
  },
  {
    text: "Hotel Management",
    key: "area_177"
  },
  {
    text: "Hotel and Restaurant Management",
    key: "area_178"
  },
  {
    text: "Real Estate Management",
    key: "area_179"
  },
  {
    text: "Tax Management",
    key: "area_180"
  },
  {
    text: "Hydrology/Water Resources Science",
    key: "area_181"
  },
  {
    text: "Dental Hygienist",
    key: "area_182"
  },
  {
    text: "History",
    key: "area_183"
  },
  {
    text: "History of the United States",
    key: "area_184"
  },
  {
    text: "Languages ​​and Foreign Literatures",
    key: "area_185"
  },
  {
    text: "Computers",
    key: "area_186"
  },
  {
    text: "Yoga Instructor",
    key: "area_187"
  },
  {
    text: "Human-Computer Interaction",
    key: "area_188"
  },
  {
    text: "Journalism",
    key: "area_189"
  },
  {
    text: "Letters - Creative Writing",
    key: "area_190"
  },
  {
    text: "Letters - English Language and English Language Literature",
    key: "area_191",
  },
  {
    text: "Letters - Literature",
    key: "area_192"
  },
  {
    text: "Lyrics – Writing",
    key: "area_193"
  },
  {
    text: "Letters, General Studies and Human Sciences",
    key: "area_194"
  },
  {
    text: "Degree in Special Education",
    key: "area_195"
  },
  {
    text: "German Language and German Language Literature",
    key: "area_196"
  },
  {
    text: "Sign Language of Mozambique",
    key: "area_197"
  },
  {
    text: "French Language and French Language Literature",
    key: "area_198",
  },
  {
    text: "English Language and English Language Literature",
    key: "area_199",
  },
  {
    text: "Japanese Language and Japanese Language Literatures",
    key: "area_200",
  },
  {
    text: "Khmer Language and Khmer Language Literature",
    key: "area_201"
  },
  {
    text: "Ukrainian Language and Ukrainian Language Literatures",
    key: "area_202",
  },
  {
    text: "Uralic Language and Uralic Language Literatures",
    key: "area_203",
  },
  {
    text: "Urdu Language and Urdu Language Literature",
    key: "area_204"
  },
  {
    text: "Turkish, Ural-Altaic, Caucasian and Central Asian Languages, Literatures and Linguistics",
    key: "area_205",
  },
  {
    text: "Linguistics",
    key: "area_206"
  },
  {
    text: "American Literature (United States)",
    key: "area_207"
  },
  {
    text: "British Literature",
    key: "area_208"
  },
  {
    text: "Children's Literature",
    key: "area_209"
  },
  {
    text: "Natural Resource Management",
    key: "area_210"
  },
  {
    text: "Marketing",
    key: "area_211"
  },
  {
    text: "International Marketing",
    key: "area_212"
  },
  {
    text: "Math",
    key: "area_213"
  },
  {
    text: "Applied Mathematics",
    key: "area_214"
  },
  {
    text: "Mathematics and Computer Science",
    key: "area_215"
  },
  {
    text: "Medicine",
    key: "area_216"
  },
  {
    text: "Dental Medicine",
    key: "area_217"
  },
  {
    text: "General Medicine",
    key: "area_218"
  },
  {
    text: "Veterinary Medicine",
    key: "area_219"
  },
  {
    text: "Weather",
    key: "area_220"
  },
  {
    text: "Microbiology",
    key: "area_221"
  },
  {
    text: "Museology, Collecting and Curatorship",
    key: "area_222"
  },
  {
    text: "Music",
    key: "area_223"
  },
  {
    text: "Music - Vocal Expression/Opera",
    key: "area_224"
  },
  {
    text: "Music - Jazz",
    key: "area_225"
  },
  {
    text: "International Business",
    key: "area_226"
  },
  {
    text: "International Business and Foreign Trade",
    key: "area_227",
  },
  {
    text: "Neurobiology and Behavior",
    key: "area_228"
  },
  {
    text: "Neuroscience",
    key: "area_229"
  },
  {
    text: "Nutrition",
    key: "area_230"
  },
  {
    text: "Chemical and Physical Oceanography",
    key: "area_231"
  },
  {
    text: "Dentistry",
    key: "area_232"
  },
  {
    text: "Legal Officer",
    key: "area_233"
  },
  {
    text: "Printer Operator",
    key: "area_234"
  },
  {
    text: "Optometry",
    key: "area_235"
  },
  {
    text: "Oratory and Rhetoric",
    key: "area_236"
  },
  {
    text: "Education Organization and Management",
    key: "area_237"
  },
  {
    text: "Academic Orientation",
    key: "area_238"
  },
  {
    text: "Plant Pathology/Phytopathology",
    key: "area_239"
  },
  {
    text: "Pedagogy - Elementary School",
    key: "area_240"
  },
  {
    text: "Pedagogy - Waldorf/Steiner",
    key: "area_241"
  },
  {
    text: "Musical Pedagogy",
    key: "area_242"
  },
  {
    text: "Legal Research",
    key: "area_243"
  },
  {
    text: "Operational Research",
    key: "area_244"
  },
  {
    text: "Financial Planning",
    key: "area_245"
  },
  {
    text: "Urban, Community or Regional Planning",
    key: "area_246",
  },
  {
    text: "National Security and Defense Policies",
    key: "area_247"
  },
  {
    text: "Historical Preservation and Conservation",
    key: "area_248"
  },
  {
    text: "Drug Use Prevention",
    key: "area_249"
  },
  {
    text: "Professor of Cosmetology, Barbershop and Manicure",
    key: "area_250",
  },
  {
    text: "Medical Residency Program - Child and Youth Psychiatry",
    key: "area_251",
  },
  {
    text: "Medical Residency Program - Urology",
    key: "area_252"
  },
  {
    text: "Medical Residency Program - Pediatric Urology",
    key: "area_253",
  },
  {
    text: "Computer Programming",
    key: "area_254"
  },
  {
    text: "Computer Programming - Specific Applications",
    key: "area_255",
  },
  {
    text: "Digital Games Programming",
    key: "area_256"
  },
  {
    text: "Psychology",
    key: "area_257"
  },
  {
    text: "Clinical Psychology",
    key: "area_258"
  },
  {
    text: "Psychology of organizations",
    key: "area_259"
  },
  {
    text: "School and Special Educational Needs Psychology",
    key: "area_260",
  },
  { text: "Industrial and Organizational Psychology", key: "area_261" },
  { text: "Social and Community Psychology", key: "area_262" },
  { text: "Advertising", key: "area_263" },
  { text: "Chemistry", key: "area_264" },
  { text: "Environmental Chemistry", key: "area_265" },
  { text: "Analytical Chemistry", key: "area_266" },
  { text: "Polymer Chemistry", key: "area_267" },
  { text: "Materials Chemistry", key: "area_268" },
  { text: "Pharmaceutical Chemistry", key: "area_269" },
  { text: "Industrial Chemistry 25 Mathematics", key: "area_270" },
  { text: "Inorganic Chemistry", key: "area_271" },
  { text: "Organic Chemistry", key: "area_272" },
  { text: "Chiropractic", key: "area_273" },
  { text: "Radio and Television", key: "area_274" },
  { text: "Vocational Rehabilitation", key: "area_275" },
  { text: "Systems and Telecommunications Networks", key: "area_276" },
  { text: "International Relations", key: "area_277" },
  { text: "Public Relations", key: "area_278" },
  { text: "Public Relations/Image Management", key: "area_279" },
  { text: "Labor and Union Relations", key: "area_280" },
  { text: "Religion/Religious Studies", key: "area_281" },
  { text: "Watchmaker and Jeweler", key: "area_282" },
  { text: "Health and Welfare – General", key: "area_283" },
  { text: "Health and Physical Education", key: "area_284" },
  { text: "Public Health", key: "area_285" },
  {
    text: "Public Safety, Law Enforcement, Fire and Related Services",
    key: "area_286",
  },
  {
    text: "Social Service",
    key: "area_287"
  },
  {
    text: "Human Services",
    key: "area_288"
  },
  {
    text: "Legal Services",
    key: "area_289"
  },
  {
    text: "Urban Forestry",
    key: "area_290"
  },
  {
    text: "Sociology",
    key: "area_291"
  },
  {
    text: "Theatre",
    key: "area_292"
  },
  {
    text: "Emergency Medical Technician and ICU",
    key: "area_293"
  },
  {
    text: "Technician in the Clinical Analysis Laboratory",
    key: "area_294",
  },
  {
    text: "Ultrasound Technician",
    key: "area_295"
  },
  {
    text: "Information Technology",
    key: "area_296"
  },
  {
    text: "Technology in Electrical and Electronic Engineering",
    key: "area_297",
  },
  {
    text: "Technology in Chemical Engineering",
    key: "area_298"
  },
  {
    text: "Machining Workshop Technology",
    key: "area_299"
  },
  {
    text: "Technology in Chemical Processes",
    key: "area_300"
  },
  {
    text: "Technology in Chemistry",
    key: "area_301"
  },
  {
    text: "Technology in Information Technology/Information Systems",
    key: "area_302",
  },
  { text: "Technology in Industrial Technology", key: "area_303" },
  { text: "Technologies Applied to Education", key: "area_304" },
  { text: "Theology/Theological Studies", key: "area_305" },
  { text: "Occupational Therapy", key: "area_306" },
  { text: "Textiles and Clothing", key: "area_307" },
  { text: "Sales, Merchandising and Marketing", key: "area_308" },
  { text: "Veterinary – Zoonoses", key: "area_309" },
  { text: "Travel", key: "area_310" },
  { text: "Virology", key: "area_311" },
  { text: "Viticulture and Enology", key: "area_312" },
  { text: "Webmaster", key: "area_313" },
  { text: "Japanese Service", key: "area_314" },
  { text: "Zoology/Animal Biology", key: "area_315" },
  { text: "Zootechnics", key: "area_316" },
  { text: "Occupational health and safety", key: "area_317" },
  { text: "All", key: "all" },
];
