module.exports = [
  { text: "Engineer", key: "engineer" },
  { text: "Analista", key: "analyst" },
  { text: "Attorney", key: "lawyer" },
  { text: "Attorney", key: "attorney" },
  { text: "Justice promoter", key: "justice_promoter" },
  { text: "Judge", key: "judge" },
  { text: "Supervisor", key: "supervisor" },
  { text: "Technician", key: "technician" },
  { text: "Biology Technician", key: "biology_technician" },
  { text: "Accountant", key: "accountant" },
  { text: "Consultant", key: "consultant" },
  { text: "Controller", key: "controller" },
  { text: "Financial analyst", key: "financial_analyst" },
  { text: "Manager", key: "manager" },
  { text: "Sales representative", key: "sales_representative" },
  { text: "Computer technician", key: "computer_technician" },
  { text: "Pharmacy Technicians", key: "pharmacy_technicians" },
  { text: "Inspector", key: "Inspector" },
  { text: "Commercial Technician", key: "commercial_technician" },
  { text: "Doctor", key: "doctor" },
  { text: "Director", key: "director" },
  { text: "Project Manager", key: "project_head" },
  { text: "Security coordinator", key: "security_coordinator" },
  { text: "Ambulance Drivers", key: "ambulance_drivers" },
  { text: "Medical Technician", key: "medical_technician" },
  { text: "Driver", key: "driver" },
  { text: "Crane Operator", key: "crane_operator" },
  { text: "Administration and Secretariat", key: "administration_and_secretariat" },
  { text: "Acquisitions and Procurement", key: "procurement_and_procurement" },
  { text: "Assistant", key: "assistant" },
  { text: "Audit and Consulting", key: "audit_and_consulting" },
  { text: "Commercial and Sales", key: "commercial_and_sales" },
  { text: "Business Development", key: "business_development" },
  { text: "law and justice", key: "law_and_justice" },
  { text: "Engineering", key: "engineering" },
  { text: "Electrical Engineer", key: "electrical_engineer" },
  { text: "Mechanical Engineer", key: "mechanical_engineer" },
  { text: "Finance and Accounting", key: "finance_and_accounting" },
  { text: "Training and Education", key: "training_and_education" },
  { text: "Geosciences", key: "Geosciences" },
  { text: "Project management", key: "project_management" },
  { text: "Quality management", key: "quality_management" },
  { text: "Management and Advisory", key: "management_and_advisory" },
  { text: "Management and Programming", key: "management_and_programming" },
  { text: "Financial management", key: "financial_management" },
  { text: "Hotel and Tourism", key: "hotel_and_tourism" },
  { text: "Computers and Programming", key: "computing_and_programming" },
  { text: "Maintenance and Technique", key: "maintenance_and_technique" },
  { text: "Marketing and Advertising", key: "marketing_and_advertising" },
  { text: "Environment", key: "environment" },
  { text: "Monitoring and Evaluation", key: "monitoring_and_evaluation" },
  { text: "Human Resources", key: "human_resources" },
  { text: "Health", key: "cheers" },
  { text: "Health and Pharmaceuticals", key: "health_and_pharmaceuticals" },
  { text: "Supervision and Coordination", key: "supervision_and_coordination" },
  { text: "Transport and Logistics", key: "Transport and Logistics" },
  { text: "Sales", key: "sales" },
  { text: "All", key: "all" },
]