const Chat = () => import('../views/Chat.vue')
const ChatRoom = () => import('../views/ChatRoom.vue')
const Feedback = () => import('../views/Feedback.vue')
const Support = () => import('../views/Support.vue')
const Ticket = () => import('../views/Ticket.vue')

export default [
  {
    path: 'support',
    component: Support,
    meta: { requiresAuth: true },
    children: [{
        path: "",
        redirect: {
          name: "support.chat"
        }
      },
      {
        path: "chat",
        name: "support.chat",
        component: Chat,
        children: [{
          path: "/support/chat/:room",
          name: "support.chat.room",
          component: ChatRoom,
          props: true,
        }, ],
      },
      {
        path: "ticket",
        name: "support.ticket",
        component: Ticket,
      },
      {
        path: "feedback",
        name: "support.feedback",
        component: Feedback,
      },
    ],
  }
]