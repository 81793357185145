export const partner = {
  namespaced: true,
  state: {
    partners: []
  },
  mutations: {
    setPartner (state, partner) {
      state.partners.unshift(partner)
    },
    setPartners (state, partners) {
      state.partners = partners
    },
    removePartner (state, id) {
      const index = state.partners.findIndex(p => p.id == id)
      state.partners.splice(index, 1)
    },
    updatePartner (state, partner) {
      const index = state.partners.findIndex(p => p.id == partner.id)
      state.partners.splice(index, 1, partner)
    }
  },
  actions: {
    setPartner ({ commit }, partner) {
      commit('setPartner', partner)
    },
    setPartners ({ commit }, partners) {
      commit('setPartners', partners)
    },
    removePartner({ commit }, id) {
      commit('removePartner', id)
    },
    updatePartner({ commit }, partner) {
      commit('updatePartner', partner)
    }
  },
  getters: {
    getPartners: (state) => {
      return state.partners
    }
  },
}