export const candidate = {
  namespaced: true,
  state: {
    candidates: [],
    blockedCandidates: []
  },
  mutations: {
    setCandidate (state, candidate) {
      const index = state.candidates.findIndex(c => c.id == candidate.id)
      if (index === -1) {
        state.candidates.unshift(candidate)
      }
    },
    setCandidates (state, candidates) {
      console.log("state.candidates: ", candidates)
      state.candidates = candidates
    },
    setCandidatesScrolled (state, candidates) {
      console.log("candidates: ", candidates)
      state.candidates.push(...candidates)
    },
    removeCandidate (state, id) {
      const index = state.candidates.findIndex(r => r.id == id)
      state.candidates.splice(index, 1)
    },
    updateCandidate (state, candidate) {
      const index = state.candidates.findIndex(r => r.id == candidate.id)
      state.candidates.splice(index, 1, candidate)
    },
    setBlockedCadsfndidates (state, blockeds) {
      state.blockedCandidates = blockeds;
    }
  },
  actions: {
    setCandidates ({ commit }, candidates) {
      commit('setCandidates', candidates)
    },
    setCandidatesScrolled ({ commit }, candidates) {
      commit('setCandidatesScrolled', candidates)
    },
    setCandidate ({ commit }, candidate) {
      commit('setCandidate', candidate)
    },
    removeCandidate ({ commit }, id) {
      commit('removeCandidate', id)
    },
    updateCandidate ({ commit }, candidate) {
      commit('updateCandidate', candidate)
    },
    setBlockedCadsfndidates({ commit }, blockeds) {
      commit('setBlockedCadsfndidates', blockeds)
    }
  },
  getters: {
    getCandidates: (state) => {
      return state.candidates
    },
    getCandidate: (state) => (id) => {
      const index = state.candidates.findIndex(c => c.id == id)
      return state.candidates[index]
    },
    getBlockedCandidates: (state) => {
      return state.blockedCandidates;
    }
  },
}