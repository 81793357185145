export const plan = {
  namespaced: true,
  state: {
    plans: []
  },
  mutations: {
    setPlan (state, plan) {
      state.plans.unshift(plan)
    },
    setPlans (state, plans) {
      state.plans = plans
    },
    removePlan (state, id) {
      const index = state.plans.findIndex(plan => plan.id == id)
      state.plans.splice(index, 1)
    },
    updatePlan (state, plan) {
      const index = state.plans.findIndex(findPlan => findPlan.id == plan.id)
      state.plans.splice(index, 1, plan)
    }
  },
  actions: {
    setPlans ({ commit }, plans) {
      commit('setPlans', plans)
    },
    setPlan ({ commit }, plan) {
      commit('setPlan', plan)
    },
    removePlan ({ commit }, id) {
      commit('removePlan', id)
    },
    updatePlan ({ commit }, plan) {
      commit('updatePlan', plan)
    }
  },
  getters: {
    getPlans: (state) => {
      return state.plans
    }
  },
}