const CV = () => import('../views/CV.vue')
const Profile = () => import('../views/Profile.vue')

export default [
  {
    path: 'candidate/cv',
    component: CV,
    meta: { requiresAuth: true },
    name: 'cv.candidate'
  },
  {
    path: 'candidate/profile',
    component: Profile,
    meta: { requiresAuth: true },
    name: 'profile.candidate'
  }
]