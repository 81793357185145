const Interview = () => import("../views/Interview.vue");
const InterviewPreview = () => import("../views/InterviewPreview.vue");
const ScheduledInterview = () => import("../views/ScheduledInterview.vue");
const ScheduledInterviewCompleted = () =>
  import("../views/ScheduledInterviewCompeted.vue");
const ScheduledInterviewApproved = () =>
  import("../views/ScheduledInterviewApproved.vue");
const ApprovedCandidate = () => import("../views/ApprovedCandidate.vue");

export default [
  {
    path: "entity/interviews",
    component: Interview,
    meta: { requiresAuth: true },
    name: "interviews",
  },
  {
    path: "entity/interviews/:id",
    component: InterviewPreview,
    meta: { requiresAuth: true },
    name: "interviews.preview",
    props: true,
  },
  {
    path: "entity/interviews/scheduled/:id",
    component: ScheduledInterview,
    meta: { requiresAuth: true },
    name: "scheduled.interviews",
    props: true,
  },
  {
    path: "entity/interviews/scheduled/:id/completed",
    component: ScheduledInterviewCompleted,
    meta: { requiresAuth: true },
    name: "completed.interviews",
    props: true,
  },
  {
    path: "entity/interviews/scheduled/:id/approved",
    component: ScheduledInterviewApproved,
    meta: { requiresAuth: true },
    name: "interviews.approved",
    props: true,
  },
  {
    path: "entity/interviews/approved/:id",
    component: ApprovedCandidate,
    meta: { requiresAuth: true },
    name: "approved.interviews",
    props: true,
  },
];
