export const interview = {
  namespaced: true,
  state: {
    interviews: []
  },
  mutations: {
    setInterview (state, interview) {
      state.interviews.unshift(interview)
    },
    setInterviews (state, interviews) {
      state.interviews = interviews
    },
    removeInterview (state, id) {
      const index = state.interviews.findIndex(r => r.id == id)
      state.interviews.splice(index, 1)
    },
    updatInterview (state, interview) {
      const index = state.interviews.findIndex(r => r.id == interview.id)
      state.interviews.splice(index, 1, interview)
    }
  },
  actions: {
    setInterviews ({ commit }, interviews) {
      commit('setInterviews', interviews)
    },
    setInterview ({ commit }, interview) {
      commit('setInterview', interview)
    },
    removeInterview ({ commit }, id) {
      commit('removeInterview', id)
    },
    updatInterview ({ commit }, interview) {
      commit('updatInterview', interview)
    }
  },
  getters: {
    getInterviews: (state) => {
      return state.interviews
    }
  },
}