export const test = {
  namespaced: true,
  state: {
    tests: []
  },
  mutations: {
    setTest (state, test) {
      state.tests.unshift(test)
    },
    setTests (state, tests) {
      state.tests = tests
    },
    setTestsScrolled (state, tests) {
      state.tests.push(...tests)
    },
    removeTest (state, id) {
      const index = state.tests.findIndex(p => p.id == id)
      state.tests.splice(index, 1)
    },
    updateTest (state, test) {
      const index = state.tests.findIndex(t => t.id == test.id)
      state.tests.splice(index, 1, test)
    }
  },
  actions: {
    setTest ({ commit }, test) {
      commit('setTest', test)
    },
    setTests ({ commit }, tests) {
      commit('setTests', tests)
    },
    setTestsScrolled ({ commit }, tests) {
      commit('setTestsScrolled', tests)
    },
    removeTest({ commit }, id) {
      commit('removeTest', id)
    },
    updateTest({ commit }, test) {
      commit('updateTest', test)
    }
  },
  getters: {
    getTests: (state) => {
      return state.tests
    }
  },
}