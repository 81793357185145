export const moduleLocal = {
  namespaced: true,
  state: {
    moduleLocals: []
  },
  mutations: {
    setModuleLocal (state, moduleLocal) {
      state.moduleLocals.unshift(moduleLocal)
    },
    setModuleLocals (state, moduleLocals) {
      state.moduleLocals = moduleLocals
    },
    removeModuleLocal (state, id) {
      const index = state.moduleLocals.findIndex(r => r.id == id)
      state.moduleLocals.splice(index, 1)
    },
    updateModuleLocal (state, moduleLocal) {
      const index = state.moduleLocals.findIndex(r => r.id == moduleLocal.id)
      state.moduleLocals.splice(index, 1, moduleLocal)
    }
  },
  actions: {
    setModuleLocals ({ commit }, moduleLocals) {
      commit('setModuleLocals', moduleLocals)
    },
    setModuleLocal ({ commit }, moduleLocal) {
      commit('setModuleLocal', moduleLocal)
    },
    removeModuleLocal ({ commit }, id) {
      commit('removeModuleLocal', id)
    },
    updateModuleLocal ({ commit }, moduleLocal) {
      commit('updateModuleLocal', moduleLocal)
    }
  },
  getters: {
    getModuleLocals: (state) => {
      return state.moduleLocals
    }
  },
}